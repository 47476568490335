import { Icon, IconProps } from "@chakra-ui/react";
import { useMemo } from "react";

let id = 0;

export function LogoWithTextIcon(props: IconProps) {
  const uniqueId = useMemo(() => { ++id; return id; }, [])

  return (
    <Icon viewBox="0 0 644 222" width="2.9em" height="1em" {...props}>
      <g fill="currentColor">
        <path d="M506.84 32.44a24.56 24.56 0 0 0-9.5-1.66c-1.7 0-3.45.13-5.25.39-1.71.25-3.42.55-5.13.9L494.78 0 474.9 3.07l-23.08 96.05h19.1l12.45-51.86c1.2-.25 2.4-.47 3.59-.64 1.28-.17 2.6-.25 3.98-.25 3.25 0 5.43.8 6.54 2.42a8.85 8.85 0 0 1 1.8 5.62c0 1.45-.18 2.98-.52 4.6a62.51 62.51 0 0 1-.9 4.6l-8.46 35.51h19.1l8.73-36.28a44.11 44.11 0 0 0 1.28-10.6c0-3.06-.42-5.92-1.28-8.56a18.23 18.23 0 0 0-3.85-6.77 16.29 16.29 0 0 0-6.54-4.47ZM246.22 20.05c.17 3.5.38 7.46.64 11.88.34 4.43.68 9.03 1.02 13.8l1.29 14.05c.43 4.68.85 9.03 1.28 13.03a384.8 384.8 0 0 0 6.8-14.05 696.06 696.06 0 0 0 13.72-31.94 649.6 649.6 0 0 0 6.54-16.22h21.17a995.9 995.9 0 0 1-20.4 46.24 566.46 566.46 0 0 1-22.31 42.28h-18.73c-.69-4.68-1.33-9.15-1.93-13.41-.5-4.34-1.02-8.6-1.53-12.77l-1.29-12.65c-.34-4.26-.68-8.69-1.02-13.28-4.88 9.02-9.7 17.88-14.5 26.56a435.3 435.3 0 0 1-15.13 25.55H183.1c-.68-7.92-1.2-15.75-1.54-23.5a733 733 0 0 1-.38-23.63c0-6.9.13-13.76.38-20.57.26-6.9.6-13.84 1.03-20.82h20.14l-1.29 22.1c-.34 7.15-.51 14.6-.51 22.35v8.82c0 2.98.09 5.96.26 8.94 2.4-4.17 4.91-8.6 7.56-13.29a1148.15 1148.15 0 0 0 15.14-27.59c2.3-4.42 4.28-8.38 5.9-11.88h16.42Z" />
        <path fillRule="evenodd" d="M342.61 70.13a42.54 42.54 0 0 0-1.02 14.68 52.1 52.1 0 0 0 2.95 13.03l-17.06 2.43c-.51-1.02-.99-2-1.41-2.94-.43-.93-.86-2-1.28-3.2a34.25 34.25 0 0 1-7.96 4.86 24 24 0 0 1-9.75 1.92c-4.27 0-7.95-.72-11.03-2.17a22.7 22.7 0 0 1-7.44-6 25.36 25.36 0 0 1-4.1-8.82 44.46 44.46 0 0 1-1.28-10.86c0-5.87 1.02-11.4 3.08-16.6a42.65 42.65 0 0 1 8.72-13.54 40.4 40.4 0 0 1 29.63-12.52c.77 0 2.1.04 3.97.13 1.97.08 4.2.3 6.67.64 2.48.25 5.1.72 7.83 1.4 2.73.68 5.34 1.62 7.82 2.81l-8.34 34.75Zm-13.34-23.5a50.3 50.3 0 0 0-3.07-.4c-.86-.08-1.93-.12-3.21-.12-2.9 0-5.64.73-8.2 2.17a23.2 23.2 0 0 0-6.55 5.75 27.27 27.27 0 0 0-4.49 8.05 29.98 29.98 0 0 0-1.54 9.58c0 4.09.69 7.32 2.05 9.7 1.37 2.39 3.9 3.58 7.57 3.58 1.97 0 3.72-.38 5.26-1.15a19.07 19.07 0 0 0 5.13-3.83c.17-2.13.43-4.34.77-6.64.43-2.39.86-4.56 1.28-6.52l5-20.18Z" clipRule="evenodd" />
        <path d="m385.31 12.13-19.88 3.07-12.57 52.88a66.63 66.63 0 0 0-1.8 12.4c-.17 3.9.43 7.4 1.8 10.47a15.55 15.55 0 0 0 6.93 7.15c3.25 1.7 7.82 2.55 13.72 2.55 4.1 0 7.66-.34 10.65-1.02a67.84 67.84 0 0 0 8.33-2.43l-1.4-14.56a51.03 51.03 0 0 1-5.9 1.66c-1.89.34-3.85.51-5.9.51-2.4 0-4.28-.34-5.65-1.02a6.3 6.3 0 0 1-2.95-2.8 10.51 10.51 0 0 1-.64-4.48c.17-1.7.51-3.53 1.02-5.49l5.65-23.5h21.16l3.85-15.59h-21.3l4.88-19.8Zm17.96 43.82a40.24 40.24 0 0 1 8.08-13.41 37.95 37.95 0 0 1 12.7-9.07 41.37 41.37 0 0 1 16.8-3.32c3.85 0 7.27.38 10.26 1.14 3.08.69 5.86 1.62 8.34 2.81l-6.54 14.82a49.86 49.86 0 0 0-5.39-1.79c-1.8-.6-4.02-.9-6.67-.9-6.41 0-11.46 2.18-15.13 6.52-3.68 4.26-5.52 10.1-5.52 17.5 0 4.35.94 7.88 2.82 10.6 1.88 2.64 5.35 3.96 10.4 3.96a34.95 34.95 0 0 0 13.34-2.68l1.4 15.2c-2.4.94-5.04 1.75-7.95 2.43a40.21 40.21 0 0 1-10.39 1.15c-5.3 0-9.79-.77-13.47-2.3a25.95 25.95 0 0 1-9.1-6.13 24.65 24.65 0 0 1-5.26-9.2 38.76 38.76 0 0 1-1.54-10.98c0-5.8.94-11.24 2.82-16.35Zm-131.39 95.63a23.96 23.96 0 0 0-9.5-1.7c-1.7 0-3.46.12-5.25.38-1.72.27-3.43.57-5.14.93l7.83-32.98-19.89 3.15-23.1 98.8h19.12l12.45-53.34c1.2-.26 2.4-.48 3.6-.66 1.28-.17 2.6-.26 3.97-.26 3.25 0 5.43.83 6.54 2.5a9.28 9.28 0 0 1 1.8 5.78c0 1.49-.17 3.06-.51 4.73a66.4 66.4 0 0 1-.9 4.73l-8.47 36.52h19.12l8.72-37.31c.86-3.6 1.29-7.23 1.29-10.9 0-3.16-.43-6.1-1.29-8.8a18.88 18.88 0 0 0-3.85-6.97 16.29 16.29 0 0 0-6.54-4.6Zm-65.06-20.89-19.88 3.15-12.58 54.4a70.27 70.27 0 0 0-1.8 12.74 24.3 24.3 0 0 0 1.8 10.77 15.8 15.8 0 0 0 6.93 7.36c3.25 1.75 7.83 2.63 13.73 2.63 4.1 0 7.66-.35 10.65-1.05 3-.7 5.77-1.54 8.34-2.5l-1.41-14.98c-1.97.7-3.94 1.27-5.9 1.71-1.88.35-3.85.53-5.9.53-2.4 0-4.28-.35-5.65-1.05a6.39 6.39 0 0 1-2.95-2.9 11.1 11.1 0 0 1-.64-4.6 38.1 38.1 0 0 1 1.02-5.64l5.65-24.18h21.17l3.85-16.03h-21.3l4.88-20.36Z" />
        <path fillRule="evenodd" d="M286.82 193.1c0-5.61.94-11.04 2.82-16.3 1.97-5.25 4.7-9.9 8.21-13.92a39.68 39.68 0 0 1 12.7-9.86 36.57 36.57 0 0 1 16.69-3.8c3 0 5.77.43 8.34 1.3 2.56.88 4.79 2.15 6.67 3.82a16.08 16.08 0 0 1 4.49 6.17 18.94 18.94 0 0 1 1.67 8.15c0 4.9-1.07 9.06-3.21 12.48a24.8 24.8 0 0 1-8.98 8.4 43.82 43.82 0 0 1-13.47 4.6 94.16 94.16 0 0 1-17.07 1.45c.6 3.68 1.88 6.3 3.85 7.88 2.05 1.5 5.26 2.24 9.62 2.24 2.74 0 5.52-.22 8.34-.66a44.34 44.34 0 0 0 7.96-2.23l1.41 15.37c-2.4.96-5.47 1.84-9.24 2.63a57.6 57.6 0 0 1-12.06 1.18c-5.3 0-9.8-.75-13.47-2.23a24.88 24.88 0 0 1-8.85-6.31 24.88 24.88 0 0 1-4.88-9.2 39.54 39.54 0 0 1-1.54-11.16Zm37.34-28.38c-4.37 0-8.21 1.62-11.55 4.86-3.25 3.15-5.39 7.36-6.42 12.61 5.3-.17 9.58-.61 12.83-1.31 3.26-.8 5.78-1.71 7.58-2.76 1.8-1.05 2.99-2.2 3.59-3.42.6-1.22.9-2.45.9-3.68 0-4.2-2.31-6.3-6.93-6.3Zm84.98 56.89c-5.16 0-10.45-.21-15.88-.65a112.2 112.2 0 0 1-14.72-2.07l20.66-86.7c4.9-.94 10.03-1.59 15.37-1.93 5.42-.35 10.37-.52 14.85-.52 5.17 0 9.6.56 13.3 1.68 3.8 1.04 6.85 2.46 9.17 4.27a16.48 16.48 0 0 1 5.3 6.47c1.2 2.42 1.8 5 1.8 7.77 0 1.9-.21 3.88-.64 5.95a18.28 18.28 0 0 1-2.33 6.2 22.14 22.14 0 0 1-4.77 5.7 29.48 29.48 0 0 1-7.88 4.92c3.87 1.73 6.71 4.1 8.52 7.12a18.36 18.36 0 0 1 2.71 9.83c0 4.14-.81 8.15-2.45 12.03a27.54 27.54 0 0 1-7.88 10.23c-3.61 2.93-8.3 5.3-14.07 7.11-5.77 1.73-12.79 2.6-21.06 2.6Zm-1.8-40.37-5.56 23.3c1.38.25 3.1.47 5.17.64 2.15.08 4.13.13 5.94.13 2.58 0 5.12-.18 7.62-.52a22.77 22.77 0 0 0 6.85-2.2 13.49 13.49 0 0 0 5.03-4.27c1.3-1.81 1.94-4.14 1.94-6.99 0-1.2-.26-2.41-.78-3.62a8.02 8.02 0 0 0-2.2-3.24 11.81 11.81 0 0 0-4.13-2.32 17.2 17.2 0 0 0-5.94-.91h-13.94Zm3.74-15.14h12.66c5.16 0 9-1.12 11.5-3.36 2.57-2.25 3.87-4.92 3.87-8.03 0-1.64-.4-3.02-1.17-4.14a7.5 7.5 0 0 0-2.84-2.84 12.34 12.34 0 0 0-4.13-1.43 21.93 21.93 0 0 0-4.78-.52c-1.8 0-3.74.1-5.8.26-2.08.09-3.63.22-4.66.4l-4.65 19.66Z" clipRule="evenodd" />
        <path d="m526.3 152.38-9.16 38.7a43.32 43.32 0 0 0-1.04 14.88c.52 4.91 1.5 9.31 2.97 13.2l-17.17 2.45a63.73 63.73 0 0 1-2.71-6.34 36.11 36.11 0 0 1-8.14 4.79 25.28 25.28 0 0 1-10.07 1.94c-5.08 0-9.22-.9-12.4-2.72a18.46 18.46 0 0 1-6.98-7.37 26.02 26.02 0 0 1-2.58-10.61 46.4 46.4 0 0 1 1.42-12.17l8.78-36.75h19.25l-8.66 36.5c-.43 1.9-.81 3.83-1.16 5.82a20.07 20.07 0 0 0 0 5.56c.26 1.64.9 3.02 1.94 4.14 1.12 1.04 2.84 1.56 5.16 1.56 1.98 0 3.8-.35 5.43-1.04a20.02 20.02 0 0 0 5.42-3.88c.17-2.16.43-4.4.78-6.73.43-2.42.86-4.62 1.29-6.6l8.4-35.33h19.23Zm45.47 15.4c1.72.35 3.45.74 5.17 1.17l5.68-15.53a25.7 25.7 0 0 0-6.98-1.81 43.42 43.42 0 0 0-7.1-.65c-5.77 0-10.97.56-15.62 1.68a140.1 140.1 0 0 0-12.4 3.37l-15.5 64.44h19.24l12.53-52.28c.95-.26 2.15-.47 3.62-.65 1.46-.26 2.8-.38 4-.38 3.27 0 5.73.21 7.36.64Zm17.41-12.29 4.78-1.42c1.8-.6 3.78-1.13 5.94-1.56 2.23-.51 4.69-.9 7.36-1.16a75.4 75.4 0 0 1 9.3-.52c10.16 0 17.13 2.93 20.92 8.8 3.78 5.87 4.43 13.89 1.93 24.07l-8.78 36.75H611.4l8.52-35.97c.52-2.25.9-4.4 1.17-6.47.34-2.16.34-4.02 0-5.57a6.24 6.24 0 0 0-2.46-3.88c-1.2-1.04-3.1-1.55-5.68-1.55-2.5 0-5.04.25-7.62.77l-12.53 52.67h-19.24l15.63-64.96Z" />
      </g>
      <circle cx="71.1" cy="151" r="71" fill={`url(#paint${uniqueId}_circle)`} />
      <path d="M121.3 0 20.9 100.58a71.21 71.21 0 0 0 0 100.59l100.4-100.59A71.21 71.21 0 0 0 121.3 0Z" fill={`url(#paint${uniqueId}_flame)`} />
      <defs>
        <linearGradient id={`paint${uniqueId}_circle`} x1="129.23" x2="22.15" y1="92.21" y2="201.54" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAB951" />
          <stop offset="1" stopColor="#FF5F52" />
        </linearGradient>
        <linearGradient id={`paint${uniqueId}_flame`} x1="71.1" x2="71.1" y1="0" y2="201.17" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C62828" />
          <stop offset="1" stopColor="#FF5F52" />
        </linearGradient>
      </defs>
    </Icon>
  )
}
